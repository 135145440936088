export const slider = () => {
  const firstScreenSlider = document.querySelector(".home-first-screen-slider");
  if (firstScreenSlider) {
    const firstScreenSliderInstanse = new Swiper(".home-first-screen-slider", {
      slidesPerView: 1,
      arrows: false,
      effect: "fade",
      autoplay: {
        delay: 5000,
      },
      pagination: {
        clickable: true,
        el: ".swiper-pagination-custom",
      },
    });
  }
  const casesImages = document.querySelector(".home-about-us-images");
  if (casesImages) {
    new Swiper(".home-about-us-images", {
      slidesPerView: 1,
      autoplay: {
        delay: 2000,
      },
      autoHeight: true,
      loop: true,
      navigation: {
        nextEl: ".images-swiper-button-next",
        prevEl: ".images-swiper-button-prev",
      },
    });
  }
  const doctorList = document.querySelector(".doctors-list");
  if (doctorList) {
    new Swiper(".doctors-list", {
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
      },

      spaceBetween: 22,
      loop: true,
      navigation: {
        nextEl: ".doctors-swiper-button-next",
        prevEl: ".doctors-swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        // when window width is >= 480px
        600: {
          slidesPerView: 2,
        },
        // when window width is >= 640px
        1024: {
          slidesPerView: 4,
        },
      },
    });
  }
};
