export const doctorModal = () => {
  const body = document.querySelector("body");
  const triggers = document.querySelectorAll(".doctor-read-more");
  const modalButtonClose = document.querySelectorAll(".modal-button-close");
  if (triggers || modalButtonClose) {
    triggers.forEach((trigger) => {
      let triggerSiblings = trigger.closest(".post").querySelectorAll(".doctor-read-more");
      let contents = trigger.closest(".post").querySelectorAll(".post-content-backdrop");
      trigger.addEventListener("click", (e) => {
        triggerSiblings.forEach((triggerSibling) => {
          if (e.target.closest(".post-read-more") !== triggerSibling) {
            triggerSibling.classList.remove("active");
          }
        });
        contents.forEach((content) => {
          if (e.target.closest(".doctor-read-more").nextElementSibling !== content) {
            // content.classList.add("is-hidden");
            content.classList.toggle("is-hidden");
            body.classList.add("overflow-hidden");
          } else {
            content.classList.toggle("is-hidden");
            body.classList.toggle("overflow-hidden");
          }
          modalButtonClose.forEach((e) => {
            e.addEventListener("click", () => {
              content.classList.add("is-hidden");
              body.classList.remove("overflow-hidden");
            });
          });
          trigger.classList.toggle("active");
        });
      });
    });
  }
};
