import { getSiblings } from "../get-siblings.js";

export const menu = () => {
  const deskSubMenu = document.querySelectorAll(
    ".header-menu .menu-nav > ul > li > .sub-menu"
  );
  const itemsHasChildren = document.querySelectorAll(
    "li.menu-item-has-children"
  );
  const subitemsHasChildren = document.querySelectorAll(
    "li.menu-item-has-children > .sub-menu > li.menu-item-has-children"
  );
  const deskShowSubMenu = (link, item) => {
    const siblings = getSiblings(item);
    if (link.closest(".header-menu")) {
      link.addEventListener("mouseover", (e) => {
        if (!item.classList.contains("active")) {
          item.classList.add("active");
          siblings.forEach((item) => {
            if (item.classList.contains("active")) {
              item.classList.remove("active");
            }
          });
          item.closest(".menu-nav-items").classList.add("active");
        }
      });
    }
  };
  itemsHasChildren.forEach((item) => {
    const link = item.querySelector("a");
    const span = document.createElement("span");
    span.classList.add("arrow");
    link.append(span);
    span.addEventListener("click", (e) => {
      e.preventDefault();
    });
  });
  $(".menu-nav-mobile>ul>li.menu-item-has-children>a>.arrow").on(
    "click",
    function () {
      $(this).closest("li").toggleClass("active");
      $(this).closest("a").siblings(".sub-menu").slideToggle(300);
    }
  );
  $(
    ".menu-nav-mobile>ul>li.menu-item-has-children>.sub-menu>li.menu-item-has-children>a>.arrow"
  ).on("click", function () {
    $(this).closest("li").toggleClass("active");
    $(this).closest("a").siblings(".sub-menu").slideToggle(300);
  });
  $(
    ".header-menu .menu-nav>ul>li.menu-item-has-children>.sub-menu>li.menu-item-has-children>a>.arrow"
  ).on("click", function () {
    // $(this).closest("li").toggleClass("active");
    // $(this).closest("a").siblings(".sub-menu").slideToggle(300);
    $(this).closest("li").toggleClass("active");
    $(this)
      .closest("a")
      .siblings(".sub-menu")
      .each(function () {
        $(this).slideToggle(300);
      });
    $(this)
      .closest("li.menu-item-has-children")
      .siblings("li.menu-item-has-children")
      .each(function () {
        $(this).removeClass("active");
        $(this).find(".sub-menu").slideUp(300);
      });
  });
};
