export const services = () => {
  $(".services-list-title.has-list").on("click", function () {
    $(this).toggleClass("active");
    $(this).closest(".services-item.has-list").toggleClass("active");
    $(this)
      .siblings(".services-item-list-container")
      .each(function () {
        $(this).slideToggle(0);
      });
    $(this)
      .closest(".services-item.has-list")
      .siblings(".services-item.has-list")
      .each(function () {
        $(this).find(".services-list-title.has-list").removeClass("active");
        $(this).find(".services-item-list-container").slideUp(0);
      });
    $(this)
      .closest(".services-item.has-list")
      .siblings(".services-item.has-list")
      .each(function () {
        $(this).removeClass("active");
      });
  });

  $(".faq-prices-item-title").on("click", function () {
    $(this).toggleClass("active");
    $(this).closest(".faq-prices-item").toggleClass("active");
    $(this)
      .siblings(".faq-prices-item-answer")
      .each(function () {
        $(this).slideToggle(200);
      });
    $(this)
      .closest(".faq-prices-item")
      .siblings(".faq-prices-item")
      .each(function () {
        $(this).find(".faq-prices-item-title").removeClass("active");
        $(this).find(".faq-prices-item-answer").slideUp(200);
      });
    $(this)
      .closest(".faq-prices-item")
      .siblings(".faq-prices-item")
      .each(function () {
        $(this).removeClass("active");
      });
  });
};
