export const modal = () => {
  const forms = document.querySelectorAll(".wpcf7");
  const header = document.querySelector("#header");
  const body = document.querySelector("body");
  const modal = document.querySelector(".backdrop-main");
  const modalButtonClose = document.querySelectorAll(".modal-button-close");
  const modalButtons = document.querySelectorAll("a");
  const windowHref = window.location.href + "#modal";
  modalButtons.forEach((modalButton) => {
    if (modalButton.href === windowHref) {
      modalButton.addEventListener("click", () => {
        forms.forEach((form) => {
          const modalWrapper = document.querySelector(".modal-main");
          modal.classList.remove("is-hidden");
          body.classList.add("overflow-hidden");
          modalButtonClose.forEach((e) => {
            e.addEventListener("click", () => {
              modal.classList.add("is-hidden");
              body.classList.remove("overflow-hidden");
            });
          });
          let formWrapper = form.closest("#kontakt");
          form.addEventListener(
            "wpcf7mailsent",
            function (event) {
              let wrapper = form.closest(".contact-form-wrapper");
              let thanks = form
                .closest(".contact-form")
                .querySelector(".contact-form-thanks");
              let headerHeight = header.offsetHeight;
              let top = formWrapper.offsetTop - headerHeight - 10;
              $("body,html").animate({ scrollTop: top }, 400);
              wrapper.classList.add("hide");
              thanks.classList.add("show");
              modalWrapper.classList.add("active");
              setTimeout(() => {
                wrapper.classList.remove("hide");
                thanks.classList.remove("show");
                modalWrapper.classList.remove("active");
              }, 8000);
            },
            false
          );
        });
      });
    }
  });
};
