export const burger = () => {
  const btns = document.querySelectorAll(".header-mobile-burger");
  const menus = document.querySelectorAll(".header-menu-mob");
  const page = document.querySelector("#page");
  const menuBackdrop = document.querySelector(".menu-backdrop");
  const headerBg = document.querySelector(".header-bg");
  btns.forEach((btn) => {
    btn.addEventListener("mouseenter", () => {
      btn.classList.add("hover");
    });
    btn.addEventListener("mouseleave", () => {
      btn.classList.remove("hover");
    });
    btn.addEventListener("click", () => {
      btn.classList.toggle("active");
      btn.classList.remove("hover");
      toggleMenu();
    });
  });

  const toggleMenu = () => {
    menus.forEach((menu) => {
      menu.classList.toggle("scale-y-0");
      menuBackdrop.classList.toggle("is-hidden");
      headerBg.classList.toggle("hidden");
    });
    page.classList.toggle("active");
    document.querySelector("body").classList.toggle("overflow-hidden");
  };
};
