import { burger } from "./modules/header/burger.js";
import { slider } from "./modules/slider.js";
import { services } from "./modules/services.js";
import { blogModal } from "./modules/blog-modal.js";
import { modal } from "./modules/modal.js";
import { contactForm } from "./modules/contact-form.js";
import { scroll } from "./modules/scroll.js";
import { menu } from "./modules/header/menu.js";
import { anchorLink } from "./modules/anchor-link.js";
import { doctorModal } from "./modules/modal-doctor.js";
document.addEventListener("DOMContentLoaded", function (event) {
  burger();
  slider();
  services();
  blogModal();
  modal();
  contactForm();
  scroll();
  menu();
  anchorLink();
  doctorModal();
});
