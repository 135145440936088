export const scroll = () => {
  const header = document.querySelector("#header");
  const scroolButton = document.querySelector(".footer-scroll-top");
  if (scroolButton) {
    $(scroolButton).click(function () {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(header).offset().top,
        },
        2000
      );
    });
  }
};
